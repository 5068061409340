function promisfy(method, proxy) {
    var proxy = proxy || this;
    var fn = function () {
        var args = [].slice.call(arguments);
        return new Promise(function (resolve, reject) {
            var cb = function () {
                var list = [].slice.call(arguments);
                resolve(list);
            }
            args.push(cb);
            method.apply(proxy, args);
        })
    }
    return fn;
}
$.ajaxSetup({
    crossDomain: true
})

function hook_ajax(){
    var _ajax = $.ajax;
    var token = sessionStorage.getItem('authorization') || '';
    $.ajax = function(args){
        if(
            !args.headers
        ){
            args.headers = {};
            args.headers.authorization = token;
            args.headers.userCard = sessionStorage.getItem('userCard');
        }else{
            args.headers.authorization = token;
            args.headers.userCard = sessionStorage.getItem('userCard');
        }
        return _ajax(args);
    }
}
hook_ajax();

function $ajax(map, fn) {
    map.success = fn;
    $.ajax(map);
}

export default {
    ajax : promisfy($ajax)
}


import tools from '@/api/tools.js';
var vm = new Vue({
    el : '.wrap'
    ,data : {
        info:{}
        ,has_tips: true
    }
    ,mounted (){

    }
    ,methods : {
        show_tips(){
            this.has_tips = true;
        }
        ,hide_tips(){
            this.has_tips = false;
        }
        ,send : async function(){
            this.info.userCard = $.trim(this.info.userCard);
            var res = await tools.ajax({
                url : call.Experts_UserLogin
                ,data :JSON.stringify(this.info)
                ,method : 'post'
                ,headers: {
                    'Content-Type': 'application/json'
                }
            })
            res = res[0];
            if(
                !res.data
            ){
                layer.msg('请确保此身份证号已在"报名系统"中绑定单位并缴纳培训费用');
            }else{
                var token = `Bearer ${res.data}`;
                sessionStorage.clear();
                sessionStorage.setItem('username',res.message);
                sessionStorage.setItem('userCard',res.error);
                sessionStorage.setItem('authorization',token);
                sessionStorage.setItem('menu',"培训考试");
                location.href= './2_1.html?=&cur_menu=%E8%AF%BE%E4%BB%B6%E4%B8%8B%E8%BD%BD&cur_comp=courseware_type&cur_parent=%E5%9F%B9%E8%AE%AD%E4%B8%93%E5%8C%BA';
            }
        }
    }
})